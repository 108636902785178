<template>
  <page-container3>
    <div class="ant-pro-table">
      <div class="ant-pro-table-search">
        <search-render
          ref="config_render"
          :form="search_config"
          :model="search_config.model"
          :options="search_config_options"
          :validateInfos="searchValidateInfos"
          @search="search"
        />
      </div>
      <a-card :body-style="{ padding: 0 }" ref="elRef">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">角色管理</div>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <a-space align="center">
                <a-button
                  type="primary"
                  size="small"
                  @click="
                    () => {
                      editModal.visible = true;
                      editModal.title = '新建角色';
                      editModal.form.model = {};
                      editModal.aim_org_id = search_modelRef.aim_org_id;
                    }
                  "
                >
                  <plus-outlined />
                  新建角色
                </a-button>
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip title="表格斑马纹">
                    <a-switch
                      checked-children="开"
                      un-checked-children="关"
                      v-model:checked="state.stripe"
                    />
                  </a-tooltip>
                </div>
              </a-space>
              <div class="ant-pro-table-list-toolbar-divider">
                <a-divider type="vertical" />
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip title="刷新">
                  <reload-outlined @click="handleTableChange" />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip title="密度">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <column-height-outlined />
                    <template #overlay>
                      <a-menu
                        style="width: 80px"
                        :selected-keys="[state.tableSize]"
                        @click="
                          ({ key }) => {
                            state.tableSize = key;
                          }
                        "
                      >
                        <a-menu-item key="default">
                          <a href="javascript:;">默认</a>
                        </a-menu-item>
                        <a-menu-item key="middle">
                          <a href="javascript:;">中等</a>
                        </a-menu-item>
                        <a-menu-item key="small">
                          <a href="javascript:;">紧凑</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-popover
                  placement="bottomRight"
                  arrowPointAtCenter
                  trigger="click"
                  overlayClassName="ant-pro-table-column-setting-overlay"
                >
                  <template #title>
                    <div class="ant-pro-table-column-setting-title">
                      <a-checkbox
                        v-model:checked="columnState.checkAll"
                        :indeterminate="columnState.indeterminate"
                        @change="handleColumnAllClick"
                      >
                        列展示
                      </a-checkbox>
                      <a @click="reset">重置</a>
                    </div>
                  </template>
                  <template #content>
                    <span class="ant-pro-table-column-setting-list">
                      <drag-container
                        lockAxis="y"
                        dragClass="ant-pro-table-drag-ghost"
                        dropClass="ant-pro-table-drop-ghost"
                        @drop="({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)"
                      >
                        <draggable :key="column.key" v-for="column in dynamicColumnItems">
                          <div class="ant-pro-table-column-setting-list-item">
                            <drag-icon />
                            <a-checkbox
                              :checked="column.checked"
                              @change="handleColumnChange($event, column)"
                            >
                              {{ column.label }}
                            </a-checkbox>
                          </div>
                        </draggable>
                      </drag-container>
                    </span>
                  </template>
                  <a-tooltip title="列设置">
                    <setting-outlined />
                  </a-tooltip>
                </a-popover>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="screenState ? '退出全屏' : '全屏'">
                  <fullscreen-outlined v-if="!screenState" @click="setFull" />
                  <fullscreen-exit-outlined v-else @click="exitFull" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <a-affix :offset-top="top">
          <a-table
            :scroll="{ y: tableSize.y }"
            :row-class-name="stripe"
            :size="state.tableSize"
            :loading="state.loading"
            :columns="dynamicColumns"
            :data-source="state.dataSource"
            :pagination="{
              current: state.current,
              pageSize: state.pageSize,
              total: state.total,
            }"
            @change="handleTableChange"
          >
            <template #index="{ index }">
              <span>{{ index + 1 + state.pageSize * (state.current - 1) }}</span>
            </template>
            <template #status="{ text }">
              <a-tag :color="statusMap[text].status">
                {{ statusMap[text].text }}
              </a-tag>
            </template>
            <template #action="{ record }">
              <a-button
                type="primary"
                @click="() => handleOpenEdit(record)"
                v-if="record.is_active"
                size="small"
              >
                修改
              </a-button>
              <a-popconfirm v-if="record.is_active" @confirm="() => handleDelete(record)">
                <template #title>
                  <p>
                    是否确认删除
                    <span style="color: dodgerblue">{{ record.name }}</span>
                    ?
                  </p>
                </template>
                <a-button type="danger" size="small">删除</a-button>
              </a-popconfirm>
              <a-button
                type="danger"
                size="small"
                @click="() => handleRecover(record)"
                v-if="!record.is_active"
              >
                恢复
              </a-button>
            </template>
          </a-table>
        </a-affix>
      </a-card>
    </div>
    <form-modal
      v-bind="editModal"
      v-if="editModal.visible"
      @cancel="
        () => {
          editModal.visible = false;
        }
      "
      @ok="handlerOk"
    />
  </page-container3>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge, message } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { addRole, queryRole, recoverRole, removeRole, updateRole } from '@/api/permission/role-manage';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '../../../components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { queryOrgList } from '@/api/sys';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'RoleManage',
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableSize = reactive({ y: window.innerHeight - 130 });
    const statusMap = {
      true: { text: '正常', status: 'green' },
      false: { text: '作废', status: 'red' },
    };
    const status_list = [
      { text: t('全部'), value: '' },
      { text: '正常', value: true },
      { text: '作废', value: false },
    ];
    const status = ['正常', '作废'];

    const baseColumns: TableColumn[] = [
      {
        title: '序号',
        width: 50,
        dataIndex: 'index',
        slots: { customRender: 'index' },
      },
      {
        title: '名称',
        width: 150,
        dataIndex: 'name',
      },
      {
        title: '描述',
        dataIndex: 'desc',
      },
      {
        title: '角色标识',
        width: 80,
        dataIndex: 'flag',
      },
      {
        title: '状态',
        dataIndex: 'is_active',
        width: 80,
        slots: { customRender: 'status' },
        filters: [
          {
            text: status[0],
            value: true,
          },
          {
            text: status[1],
            value: false,
          },
        ],
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        slots: { customRender: 'action' },
      },
    ];

    onMounted(() => {
      window.onresize = function () {
        tableSize.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, true);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const org_list: any = reactive([]);
    queryOrgList().then(res => {
      res.data.forEach(org => {
        org_list.push(org);
      });
      console.log(org_list);
    });
    const search_modelRef = reactive({
      org_id: current_org.id,
      aim_org_id: current_org.id,
      is_active: true,
    });
    const search_config_options = {
      is_active: [
        { name: '全部', value: '' },
        { name: '正常', value: true },
        { name: '作废', value: false },
      ],
      aim_org_id: org_list,
    };
    const search_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'select',
          name: 'aim_org_id',
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请填组织id',
          datasourceType: 'remote',
          datasource: 'aim_org_id',
          mode: 'default',
        },
        {
          type: 'input',
          name: 'name',
          label: '角色名称',
          label_i18n: '角色名称',
          placeholder_i18n: '请填写角色名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写角色名称',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
        },
      ],
      rules: {
        name: [],
      },
      model: search_modelRef,
    };
    const { validateInfos: searchValidateInfos } = useForm(search_modelRef, {});

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryRole, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current,
        pageSize,
        ...filters,
        ...search_modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...search_modelRef,
      });
      reload();
    };
    // edit
    const edit_model_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'horizontal',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '角色名称',
          label_i18n: '角色名称',
          placeholder_i18n: '请填写角色名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写角色名称',
        },
        {
          type: 'input',
          name: 'flag',
          label: '角色标识',
          label_i18n: '角色标识',
          placeholder_i18n: '请填写角色标识',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写角色标识',
        },
        {
          type: 'textarea',
          name: 'desc',
          label: '角色描述',
          label_i18n: '角色描述',
          placeholder_i18n: '请填写角色描述',
          disabled: false,
          allowClear: true,
          defaultValue: '',
          placeholder: '请填写角色描述',
        },
      ],
      rules: {
        name: [{ required: true, message: '角色名称必须填写' }],
      },
      model: {
        id: null,
        name: '',
        desc: '',
      },
    };
    const edit_model_config_options = {};
    const editModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: edit_model_config,
      options: edit_model_config_options,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      editModal.visible = true;
      editModal.title = '修改角色';
      editModal.form.model = { ...record };
      currentRecord = record;
    };

    const handleDelete = (record: any) => {
      removeRole({ role_id: record.id, org_id: current_org.id }).then(res => {
        message.success('删除角色成功');
        Object.assign(record, res);
      });
    };

    const handleRecover = (record: any) => {
      recoverRole({ role_id: record.id, org_id: current_org.id }).then(res => {
        message.success('恢复角色成功');
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      if (!editModal.form.model.id) {
        addRole({ ...data, org_id: current_org.id, aim_org_id: editModal.aim_org_id }).then(() => {
          message.success('新增角色成功');
          reload();
        });
      } else {
        updateRole({
          ...data,
          org_id: current_org.id,
          aim_org_id: data.org_id,
          role_id: editModal.form.model.id,
        }).then((res: any) => {
          // currentRecord.name = res.name;
          Object.assign(currentRecord, res);
          message.success('修改角色成功');
        });
      }
      editModal.visible = false;
      // reload();
    };

    return {
      statusMap,
      tableSize,
      state,
      org_list,
      status_list,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handlerOk,
      currentRecord,
      search_modelRef,
      searchValidateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      addRole,
      updateRole,
      removeRole,
      recoverRole,
      // edit
      edit_model_config,
      edit_model_config_options,
      editModal,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      search_config,
      search_config_options,
    };
  },
  components: {
    SearchRender,
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    [Badge.name]: Badge,
    FormModal,
  },
});
</script>
